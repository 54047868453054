<template>
  <div :class="isSearch ? 'text-left' : 'text-center'">
    <v-dialog v-model="dialog" width="1000" @click:outside="close">
      <template v-if="isSearch" v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" @click="setClicked">
          {{ prod.producto.descripcion_corta }}
        </div>
      </template>
      <template v-else v-slot:activator="{ on, attrs }">
        <div class="img-container">
          <picture>
            <source
              :srcset="defaultImage(prod.producto.imagenes[0])"
              type="image/webp"
            />
            <img
              class="img-prod img-prod-transform"
              v-bind="attrs"
              v-on="on"
              :src="defaultImage(prod.producto.imagenesPNG[0])"
              :alt="prod.producto.descripcion_corta"
            />
          </picture>
        </div>
      </template>

      <v-card>
        <v-card-text>
          <v-btn
            icon
            rounded
            @click="closeModal()"
            style="float:right;top:12px;"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-row class="pa-3">
            <v-col cols="12" sm="12" lg="6">
              <v-carousel class="carousel-img-prods" delimiter-icon="mdi-minus">
                <v-carousel-item
                  v-for="(img, i) in prod.producto.imagenes"
                  :key="i"
                  :src="defaultImage(img)"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                >
                </v-carousel-item>
              </v-carousel>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              lg="6"
            >
              <p class="display-2 text--primary">
                {{ prod.producto.nombre_producto }}
              </p>
              <v-rating
                :value="prod.producto.calificacion"
                color="primary"
                background-color="secondary"
                dense
                half-increments
                readonly
                size="24"
              >
              </v-rating>
              <div class="text--primary mt-8">
                {{ prod.producto.descripcion_corta }} <br />
                {{ prod.producto.descripcion_larga }} <br />
                {{ prod.producto.sku }} <br />
                {{ prod.producto.marca.dato }} <br />
                Se vende por: {{ prod.producto.unidad_medida.dato }}
              </div>
              <div class="display-1 text--primary mt-6">
                Precio: ${{ prod.producto.precio }}
              </div>
              <div>
                <small
                  v-if="
                    prod.producto.backorder && prod.producto.backorder.backorder
                  "
                  class="text-caption"
                >
                  (Backorder con tiempo de entrega de
                  {{ prod.producto.backorder.tiempo_entrega_dias }} días)
                </small>
                <small v-else class="text-caption">
                  ({{ prod.cantidad_disponible }} disponibles)
                </small>
              </div>
              <div class="text-primary my-2">
                <small v-for="offer in prod.descuento" :key="offer.id">
                  Obten el {{ offer.porcentaje_descuento * 100 }}% de descuento
                  en compra mínima de {{ offer.cantidad_compra_minima }} ó más
                  productos<br />
                </small>
              </div>
              <div class="text-h6 text--primary mt-6">
                Presentación :
                <div
                  v-for="(opcion, i) in prod.producto.opcion_producto"
                  :key="i"
                  class="text-h6 text--primary d-inline-block mx-6"
                >
                  {{ opcion.nombre_opcion }}
                </div>
              </div>
              <v-divider class="mt-4"></v-divider>
              <span>Archivos:</span>
              <v-btn
                v-for="doc in prod.producto.documentos"
                :key="doc.id"
                class="ma-2"
                text
                icon
                color="primary"
                :href="doc.documento"
                target="_blank"
              >
                <v-icon>mdi-file-download-outline</v-icon>
              </v-btn>
              <v-divider></v-divider>
              <v-card-actions v-if="showingCart">
                <v-btn
                  color="primary"
                  class="mt-6"
                  block
                  @click="addProductoToCart(prod)"
                >
                  Añadir al carrito
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { inventoryMixin } from "@/mixins/inventory.js";

export default {
  mixins: [inventoryMixin],
  props: {
    prod: {
      type: Object,
      required: true,
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    ...mapMutations("cart", ["addProduct"]),

    addProductoToCart(product) {
      this.addProduct(product);
    },
    close() {
      //alert('Se presiono el nombre de producto')
      if (this.isSearch) {
        this.$emit("clearText");
      }
    },
    closeModal() {
      this.dialog = false;
    },
    setClicked() {
      if (this.isSearch) {
        //console.log('se hizo clik desde IconsDialog');
        this.$emit("setClicked");
      }
    },
  },
  computed: {
    ...mapGetters("usuario", ["showingCart"]),
  },
};
</script>
<style>
.img-container {
  height: 150px !important;
  width: 100%;
}
.img-prod {
  height: 150px !important;
  width: 100%;
  object-fit: cover;
}
.img-prod-transform {
  transition: transform 0.2s;
}
.img-prod-transform:hover {
  cursor: pointer;
  transform: scale(1.1);
}
.carousel-img-prods .v-image__image--cover {
  background-size: contain !important;
}
</style>
