var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.isSearch ? 'text-left' : 'text-center'},[_c('v-dialog',{attrs:{"width":"1000"},on:{"click:outside":_vm.close},scopedSlots:_vm._u([(_vm.isSearch)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({on:{"click":_vm.setClicked}},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.prod.producto.descripcion_corta)+" ")])]}}:{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"img-container"},[_c('picture',[_c('source',{attrs:{"srcset":_vm.defaultImage(_vm.prod.producto.imagenes[0]),"type":"image/webp"}}),_c('img',_vm._g(_vm._b({staticClass:"img-prod img-prod-transform",attrs:{"src":_vm.defaultImage(_vm.prod.producto.imagenesPNG[0]),"alt":_vm.prod.producto.descripcion_corta}},'img',attrs,false),on))])])]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',[_c('v-btn',{staticStyle:{"float":"right","top":"12px"},attrs:{"icon":"","rounded":""},on:{"click":function($event){return _vm.closeModal()}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-row',{staticClass:"pa-3"},[_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"6"}},[_c('v-carousel',{staticClass:"carousel-img-prods",attrs:{"delimiter-icon":"mdi-minus"}},_vm._l((_vm.prod.producto.imagenes),function(img,i){return _c('v-carousel-item',{key:i,attrs:{"src":_vm.defaultImage(img),"reverse-transition":"fade-transition","transition":"fade-transition"}})}),1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"6"}},[_c('p',{staticClass:"display-2 text--primary"},[_vm._v(" "+_vm._s(_vm.prod.producto.nombre_producto)+" ")]),_c('v-rating',{attrs:{"value":_vm.prod.producto.calificacion,"color":"primary","background-color":"secondary","dense":"","half-increments":"","readonly":"","size":"24"}}),_c('div',{staticClass:"text--primary mt-8"},[_vm._v(" "+_vm._s(_vm.prod.producto.descripcion_corta)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.prod.producto.descripcion_larga)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.prod.producto.sku)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.prod.producto.marca.dato)+" "),_c('br'),_vm._v(" Se vende por: "+_vm._s(_vm.prod.producto.unidad_medida.dato)+" ")]),_c('div',{staticClass:"display-1 text--primary mt-6"},[_vm._v(" Precio: $"+_vm._s(_vm.prod.producto.precio)+" ")]),_c('div',[(
                  _vm.prod.producto.backorder && _vm.prod.producto.backorder.backorder
                )?_c('small',{staticClass:"text-caption"},[_vm._v(" (Backorder con tiempo de entrega de "+_vm._s(_vm.prod.producto.backorder.tiempo_entrega_dias)+" días) ")]):_c('small',{staticClass:"text-caption"},[_vm._v(" ("+_vm._s(_vm.prod.cantidad_disponible)+" disponibles) ")])]),_c('div',{staticClass:"text-primary my-2"},_vm._l((_vm.prod.descuento),function(offer){return _c('small',{key:offer.id},[_vm._v(" Obten el "+_vm._s(offer.porcentaje_descuento * 100)+"% de descuento en compra mínima de "+_vm._s(offer.cantidad_compra_minima)+" ó más productos"),_c('br')])}),0),_c('div',{staticClass:"text-h6 text--primary mt-6"},[_vm._v(" Presentación : "),_vm._l((_vm.prod.producto.opcion_producto),function(opcion,i){return _c('div',{key:i,staticClass:"text-h6 text--primary d-inline-block mx-6"},[_vm._v(" "+_vm._s(opcion.nombre_opcion)+" ")])})],2),_c('v-divider',{staticClass:"mt-4"}),_c('span',[_vm._v("Archivos:")]),_vm._l((_vm.prod.producto.documentos),function(doc){return _c('v-btn',{key:doc.id,staticClass:"ma-2",attrs:{"text":"","icon":"","color":"primary","href":doc.documento,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-file-download-outline")])],1)}),_c('v-divider'),(_vm.showingCart)?_c('v-card-actions',[_c('v-btn',{staticClass:"mt-6",attrs:{"color":"primary","block":""},on:{"click":function($event){return _vm.addProductoToCart(_vm.prod)}}},[_vm._v(" Añadir al carrito ")])],1):_vm._e()],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }